.catalogHeadline {
    color: #005f73;
    font-size: 24px;
    font-weight: normal;
}

#catalogView h1 {
    color: #005f73;
    font-size: 24px;
    font-weight: lighter;
}

