#main-login .login-form {
    max-width: 600px;
    display: block;
    margin: 20px;
}
#main-login .login-form-forgot {
    float: right;
}
#main-login .ant-col-rtl .login-form-forgot {
    float: left;
}
#main-login .login-form-button {
    width: 100%;
    margin-top: 20px;
}

#main-login .logo {
       margin-top: 20px;
       margin-bottom: 30px;
}

.error-message {
    color: red;
}